import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from '../../components/nav-menu/nav';
import { apiCall } from '../../components/ApiFunctions'
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import heroImg from '../../assets/white-background-shape-v2.png';
import footerImg from '../../assets/footer.png'
import './order-style.scss';

const HeroShapeBg = styled.div`
  background: url(${heroImg});
`;

export default function Orders(props) {

  const [orderData, setOrderData] = useState([]);

  useEffect( async () => {
    const data = await apiCall.getAllInfo('get_orders').then((res) => {
      return res.body
    })
    setOrderData(data);
  }, []);

  // useEffect(() => {
  //   const ac = new AbortController();
  //   Promise.all([
  //     apiCall.getAllInfo('get_orders').then((res) => {
  //       return res.body
  //     })
  //   ]).then((data) => setOrderData(data))
  //     .catch(ex => console.error(ex));
  //   return () => ac.abort(); // Abort both fetches on unmount
  // }, []);

  console.log({ orderData });

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_6he6riw', 'template_j7f9926', e.target, 'user_xi7mUaoVTXsI7vhIe8BZn')
      .then((result) => {
          SuccefullMail()
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

  const SuccefullMail = () => {
    console.log('SuccefullMail');
    return (
      <div className='form'>
        <div className='succefull-email'>
          <div className='cheked-icon'>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle class="path circle" fill="none" stroke="#61869A" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline class="path check" fill="none" stroke="#61869A" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          </div>
          <h3>We received your details</h3>
          <p>Our team will contact you shortly!</p>
          <div className='home-btn'>
            <Link to='/'><button>Main Page</button></Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mainPage">
      <HeroShapeBg className='HeroShapeBg'/>
      <section className='bifold-wrapper'>
        <NavMenu />
        <main className='buifold-content__pages'>
          <div className='pages-title'>
            <h1>All Orders</h1>
          </div>
          <div className='pages-wrapper'>
            <div className="order__page orders__page">
              <table>
                <thead>
                  <tr>
                    <th>Quote No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Submitted On</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((data, index)=>{
                      return (
                        <tr key={index}>
                          <td><a href={`/order/${data.quote_no}`}>{data.quote_no}</a></td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                          <td>£{data.price}</td>
                          <td>{data.payment_status}</td>
                          <td>{data.time}</td>
                        </tr>
                      )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </section>
      <footer className="footer">
        <p>Powered by</p>
        <a href="https://essexwebdesignstudio.com/bifold-builder/" target="_blank">
          <img class="footer__logo" src={footerImg} alt="logo"></img>
        </a>
      </footer>
    </div>
  )
}


