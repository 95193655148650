import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import hamburger from "./hamburger.svg";
import hamburgerWhite from "./hamburger-white.svg";
import hamburgerHover from "./hamburgerHover.svg";
import hamburgerHoverWhite from "./hamburgerHover-white.svg";
import close from "./close.svg";
import { breakpoints } from "../../Media";

const HamburgerContainer = styled.div`
  /* position: fixed; */
  right: 0px;
  top: 0px;
  height: 100%;
  width: 55%;
  z-index: 9999;
  display: none;

  //@media (max-width: ${breakpoints.desktopMin}) {
    display: block;
    right: 20px;
    top: 20px;
    width: unset;
  //}
`;

const BurgerMenu = styled.div`

  z-index: 99999;
  display: block;
  position: ${props => (props.on ? "fixed" : "fixed")};
  top: 52px;
  right: 40px;

  label{
    display:flex;
    flex-direction:column;
    width:44px;
    cursor:pointer;
  }

  label span{
    background: #ffffff;
    border-radius:10px;
    height: 4px;
    margin: 3px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1){
    transform-origin: ${props => props.on ? 'initial' : 'bottom'};
    width: ${props => props.on ? '50%' : '40%'};
    transform: ${props => props.on ? 'rotate(0)' : 'rotatez(45deg) translate(6px,3px)'};
  }

  span:nth-of-type(2){
    transform-origin: ${props => props.on ? 'initial' : 'top'};
    width: ${props => props.on ? '100%' : '78%'};
    transform: ${props => props.on ? 'rotate(0)' : 'rotatez(-45deg) translate(-3px,1px)'};
  }

  span:nth-of-type(3){
    transform-origin: ${props => props.on ? 'initial' : 'bottom'};
    width: ${props => props.on ? '75%' : '40%'};
    transform: ${props => props.on ? 'rotate(0)' : 'rotatez(45deg) translate(8px,-11px)'};
  }

  @media (max-width: 1314px) {
    position: ${props => (props.on ? "absolute" : "fixed")};
    label span{
      background: ${props => props.on ? '#46545b' : '#ffffff'};
    }
  }
`;

const HamburgerImg = styled.div`
  z-index: 99999;
  display: block;
  position: ${props => (props.on ? "absolute" : "fixed")};
  top: 60px;
  right: 80px;
  width: ${props => (props.on ? `40px` : `40px`)};
  height: 30px;
  background-image: ${props => props.on ? `url(${hamburgerWhite})` : `url(${close})`};
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 1;
  transform: translate(40px);
  transition-duration: 0.2s;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  cursor: pointer;

  /*
  &:hover {
    background-image: ${props => props.on ? `url(${hamburgerHoverWhite})` : `url(${close})`};
  }
  */

  @media (max-width: 1314px) {
    background-image: ${props => props.on ? `url(${hamburger})` : `url(${close})`};

    /*
    &:hover {
      background-image: ${props => props.on ? `url(${hamburgerHover})` : `url(${close})`};
    }
    */
  }

  @media (max-width: ${breakpoints.tabletMin}) {
    top: 50px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const NavContainer = styled.div`
  height: 100%;
  z-index: 999;
  width: 60%;
  padding: 0 30px;
  background: linear-gradient(238.96deg, #61869A 32.79%, #051f3f 98.55%); 
  //position: ${props => (props.on ? "fixed" : "absolute")};
  position: fixed;
  display: block;
  right: 0;
  top: 0;
  opacity: 0;
  animation: ${props =>
    props.on ? "SlideOutRight .7s forwards" : "SlideFromLeft .5s forwards"};
  box-shadow: -11px 2px 48px -6px rgba(0,0,0,0.75);

  @media (min-width: ${breakpoints.tabletMin}) {
    width: 400px;
  }
  @media (max-width: ${breakpoints.mobileMin}) {
    width: 70%;
  }
  @keyframes SlideFromLeft {
    0% {
      transform: translate(1000px);
      opacity: 0;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes SlideOutRight {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    100% {
      transform: translate(1000px);
      opacity: 0;
    }
  }
`;

const Links = styled.div`
  margin-top: 130px;
  width: auto;
  height: 40%;
  & > a {
    display: block;
    color: #ffffff;
    text-decoration: none;
    font-size: 28px;
    line-height: 91.45%;
    position: relative;
    z-index: 2;
    opacity: 0;
    border-top: 1px solid #ffffff;
    padding: 15px 10px;
    animation: ${props => (props.on ? "none" : "SlideInText .7s forwards")};
    transition: all .4s ease-in-out;  
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
    &:last-of-type {
      border-bottom: 1px solid #ffffff;
    }
    @keyframes SlideInText {
      0% {
        transform: translate(100px);
        opacity: 0;
      }
      100% {
        transform: translate(0);
        opacity: 1;
      }
    }
    &::after {
      width: 0px;
      left: -10px;
      content: "";
      display: inline-block;
      height: 20px;
      position: absolute;
      bottom: 0px;
      z-index: -2;
      transition: width 0.4s;
    }
    &:hover {
      padding: 15px 20px;
    }
  }

  @media (max-width: ${breakpoints.tabletMin}) {
    margin-top: 120px;
  }
`;

class Hamburger extends Component {
  state = {
    active: false
  };

  constructor() {
    super();
    this.state = {
      active: true
    };
  }
  render() {
    return (
      <HamburgerContainer>
        {/* <HamburgerImg
          on={this.state.active}
          onClick={() => this.setState({ active: !this.state.active })}
          //onMouseEnter={() => this.setState({ active: (this.state.active === true) ? true : false })}
        /> */}
        <BurgerMenu on={(this.state.active === true) ? 1 : 0} onClick={() => this.setState({ active: !this.state.active })}>
          <label>
            <span></span>
            <span></span>
            <span></span>
          </label>
        </BurgerMenu>
        <NavContainer on={(this.state.active === true) ? 1 : 0}>
          <Links on={(this.state.active === true) ? 1 : 0}>
            <Link to="/">Design</Link>
            <Link to="/faqs">FAQs</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <a href='https://essexwebdesignstudio.com/bifold-builder/' target='_blank' rel="noopener noreferrer">Main Site</a>
            <Link to="/contact">Contact</Link>
          </Links>
        </NavContainer>
      </HamburgerContainer>
    );
  }
}

export default Hamburger;
