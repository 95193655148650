import React, { Component } from 'react';
import Home from './MainPage';
import FaqsPage from './pages/faqs/faqs';
import PrivacyPage from './pages/privacyPolicy/privacyPolicy';
import ContactPage from './pages/contact/contact';
import QuotePage from './pages/quote/quote';
import OrdersPage from './pages/order/orders';
import OrderPage from './pages/order/order';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

class App extends Component {
  loading = () => (
    <div className='animated fadeIn p-2 text-center'>
      Loading... <i className='fa fa-spin fa-spinner fa-lg'></i>
    </div>
  )

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/faqs" component={FaqsPage} />
          <Route path="/privacy-policy" component={PrivacyPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/quote" component={QuotePage} />
          <Route path="/orders" component={OrdersPage} />
          <Route path="/order/:orderid" component={OrderPage} />
        </Switch>
      </Router>
    )
  }
}

export default App