const predefinedHeaders = require('./predefinedHeaders')

module.exports = {
  serverErrors: (error) => {
    if (error.response) {
      console.log(error.response.data)
      let err = error.response.data
      let errResponse = err.body.errorMessage ? err.body.errorMessage : err.header.message
      let errDetails = err.body.errorDetails ? err.body.errorDetails : predefinedHeaders.somethingWentWrong.message

      return {
        header: { code: err.header.code },
        body: {
          errorMessage: errResponse ? errResponse : predefinedHeaders.somethingWentWrong.message,
          errorDetails: errDetails
        }
      }
    } else {
      console.log(error.message)
      return {
        header: { code: '001' },
        body: {
          errorMessage: error.message,
          errorDetails: predefinedHeaders.somethingWentWrong.message
        }
      }
    }
  }
}
