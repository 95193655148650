module.exports = {
  validationError: {
    code: 100,
    message: 'Validation Error!'
  },

  noDataFound: {
    code: 101,
    message: 'No data found!'
  },

  pageNotFound: {
    code: 102,
    message: 'Page not found!'
  },

  notAllowed: {
    code: 103,
    message: 'Request Error!'
  },

  somethingWentWrong: {
    code: 104,
    message: 'Something went wrong, please try again later!'
  },
}
