import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import NavMenu from '../../components/nav-menu/nav';
import { apiCall } from '../../components/ApiFunctions'
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import heroImg from '../../assets/white-background-shape-v2.png';
import footerImg from '../../assets/footer.png'
import './order-style.scss';

const HeroShapeBg = styled.div`
  background: url(${heroImg});
`;

export default function Order(props) {

  const orderid = props.match.params.orderid;
  const [orderData, setOrderData] = useState('');

  useEffect( async () => {
    if (!orderData) {
      const data = await apiCall.getInfoById('get_order', orderid).then((res) => {
        return JSON.parse(res.body.data)
      })
      setOrderData(data);
    }
  }, []);

  console.log({ orderData });

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_6he6riw', 'template_j7f9926', e.target, 'user_xi7mUaoVTXsI7vhIe8BZn')
      .then((result) => {
          SuccefullMail()
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

  const SuccefullMail = () => {
    console.log('SuccefullMail');
    return (
      <div className='form'>
        <div className='succefull-email'>
          <div className='cheked-icon'>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle class="path circle" fill="none" stroke="#61869A" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline class="path check" fill="none" stroke="#61869A" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          </div>
          <h3>We received your details</h3>
          <p>Our team will contact you shortly!</p>
          <div className='home-btn'>
            <Link to='/'><button>Main Page</button></Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mainPage">
      <HeroShapeBg className='HeroShapeBg'/>
      <section className='bifold-wrapper'>
        <NavMenu />
        <main className='buifold-content__pages'>
          <div className='pages-title'>
            <h1>Quote No. {orderid}</h1>
          </div>
          <div className='pages-wrapper'>
            <div className="order__page">

              <div className='row mb-4'>
                <h2 className='text-center'>Your Bifold Quote</h2>
              </div>

              {/* user details */}
              <div className='panel mb-4'>
                <div className='row mb-2'>
                  <h3>Your Details</h3>
                </div>
                <div>
                  <table className='table'>
                    <tr>
                      <td>Name</td>
                      <td>{orderData.yourName}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{orderData.email}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{orderData.telephone}</td>
                    </tr>
                    <tr>
                      <td>Postcode</td>
                      <td>{orderData.postcode}</td>
                    </tr>
                  </table>
                </div>
              </div>

              {/* door specs */}
              <div className='panel mb-4'>
                <div className='row mb-2'>
                  <h3>Door Specs</h3>
                </div>
                <div>
                  <table className='table'>
                    <tr>
                      <td>Material</td>
                      <td>{orderData.doorMaterial}</td>
                    </tr>
                    <tr>
                      <td>Width</td>
                      <td>{orderData.width}</td>
                    </tr>
                    <tr>
                      <td>Height</td>
                      <td>{orderData.height}</td>
                    </tr>
                    <tr>
                      <td>Panels</td>
                      <td>{orderData.panels}</td>
                    </tr>
                    <tr>
                      <td>Door colour</td>
                      <td>{orderData.doorColour}</td>
                    </tr>
                    <tr>
                      <td>Handle colour</td>
                      <td>{orderData.handleColour}</td>
                    </tr>
                    <tr>
                      <td>Projecting cill</td>
                      <td>{orderData.projectingCill}</td>
                    </tr>
                    <tr>
                      <td>Threshold</td>
                      <td>{orderData.threshold}</td>
                    </tr>
                    <tr>
                      <td>Trickle vents</td>
                      <td>{orderData.trickleVents}</td>
                    </tr>
                    <tr>
                      <td>Integral blinds</td>
                      <td>{orderData.integralBlinds}</td>
                    </tr>
                    <tr>
                      <td>Folding style</td>
                      <td>{orderData.foldingStyle}</td>
                    </tr>
                    <tr>
                      <td>Opening style</td>
                      <td>{orderData.openingStyle}</td>
                    </tr>
                  </table>
                </div>
              </div>

              {/* supply quote */}
              <div className='panel mb-4'>
                <div className='row mb-2'>
                  <h3>Your supply only quote</h3>
                </div>
                <div>
                  <table className='table'>
                    <tr>
                      <td><h1>£{orderData.price}</h1></td>
                    </tr>
                    <tr>
                      <td>Inclusive of VAT And Delivery</td>
                    </tr>
                  </table>
                </div>
              </div>


              <div className='panel mb-4'>
                <form className='contact-form'>
                  <input className='submit-btn' type='submit' value={`Pay Now £${orderData.price/2}`}/>
                </form>
              </div>

            </div>
          </div>
        </main>
      </section>
      <footer className="footer">
        <p>Powered by</p>
        <a href="https://essexwebdesignstudio.com/bifold-builder/" target="_blank">
          <img class="footer__logo" src={footerImg} alt="logo"></img>
        </a>
      </footer>
    </div>
  )
}


