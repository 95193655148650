const err = require('../ErrorHandlers')
const env = process.env.NODE_ENV || 'development'
const config = require('../../config/config.json')
const axios = require('axios')
const apiUrl = config[env].proxy

module.exports = (baseUrl) => {
  var module = {}

  module.create = (type, data) => {
    return axios
      .post(`${apiUrl}/${baseUrl}/${type}`, data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  module.update = (type, data, id) => {
    return axios
      .put(`${apiUrl}/${baseUrl}/${type}/${id}`, data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  module.remove = (type, data, id) => {
    return axios
      .delete(`${apiUrl}/${baseUrl}/${type}/${id}`, { data: data })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  module.getAllInfo = (type) => {
    return axios
      .get(`${apiUrl}/${baseUrl}/${type}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  module.getInfoById = (type, id) => {
    return axios
      .get(`${apiUrl}/${baseUrl}/${type}/${id}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  module.getInfoByQuery = (type, query) => {
    return axios
      .get(`${apiUrl}/${baseUrl}/${type}/?${query}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return err.serverErrors(error)
      })
  }

  return module
}
